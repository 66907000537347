var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mt-2 mb-2 fs-16 fw-700" }, [
        _vm._v(_vm._s(_vm.FormMSG(854, "Main"))),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: _vm.detailsForGreenManager ? 3 : _vm.isLocation ? 5 : 3,
                lg: _vm.detailsForGreenManager ? 3 : _vm.isLocation ? 5 : 3,
                xl: _vm.detailsForGreenManager ? 3 : _vm.isLocation ? 5 : 3,
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.isLocation
                      ? _vm.FormMSG(863, "Electricity (Location)")
                      : _vm.FormMSG(862, "Electricity"),
                  },
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value:
                            _vm.currentLocation.consumptionElectricityTotal,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.FormMSG(1259, "kWh"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isLocation
                ? _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mb-2",
                      attrs: { id: "electricityFromPhotovoltaic" },
                      model: {
                        value:
                          _vm.currentLocation.electricityFromPhotovoltaicSource,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentLocation,
                            "electricityFromPhotovoltaicSource",
                            $$v
                          )
                        },
                        expression:
                          "currentLocation.electricityFromPhotovoltaicSource",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              858,
                              "Electricity from photovoltaic source"
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.isLocation
            ? _c(
                "b-col",
                {
                  attrs: {
                    sm: "12",
                    md: _vm.detailsForGreenManager ? 3 : _vm.isLocation ? 5 : 3,
                    lg: _vm.detailsForGreenManager ? 3 : _vm.isLocation ? 5 : 3,
                    xl: _vm.detailsForGreenManager ? 3 : _vm.isLocation ? 5 : 3,
                  },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(
                          861,
                          "Electricity (ancillary space)"
                        ),
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            staticClass: "bg-color-grams-hair",
                            attrs: {
                              value:
                                _vm.currentLocation
                                  .consumptionElectricityAncillarySpaceTotal,
                              disabled: "",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass:
                                    "bg-color-north-wind text-color-white",
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(_vm.FormMSG(1259, "kWh")))]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "mb-2",
                      attrs: { id: "electricityFromPhotovoltaicSpace" },
                      model: {
                        value:
                          _vm.currentLocation
                            .electricityFromPhotovoltaicSourceAncillarySpace,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentLocation,
                            "electricityFromPhotovoltaicSourceAncillarySpace",
                            $$v
                          )
                        },
                        expression:
                          "currentLocation.electricityFromPhotovoltaicSourceAncillarySpace",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              858,
                              "Electricity from photovoltaic source"
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
                lg: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
                xl: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(856, "Gas") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.currentLocation.consumptionGasTotal,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("m"),
                                _c("sup", [_vm._v("3")]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
                lg: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
                xl: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(1, "Water") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.currentLocation.consumptionWaterTotal,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v("m"),
                                _c("sup", [_vm._v("3")]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            {
              attrs: {
                lg: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
                md: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
                xl: _vm.detailsForGreenManager ? 2 : _vm.isLocation ? 4 : 3,
              },
            },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(702, "Fuel oil") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.currentLocation.volumeTotalFuelOil,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.generatorUnit)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.handleClickeAddEditConsumption },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(705, "Add/Edit consumption")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }