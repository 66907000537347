<template>
	<b-modal
		id="location-entry-carbon-dialog"
		v-model="isOpen"
		header-class="header-class-modal-doc-package"
		:title="FormMSG(122, 'Location energy consumption')"
		size="xl"
		no-close-on-backdrop
		no-close-on-esc
		:hide-backdrop="hideBackdropDialog"
		:cancel-title="FormMSG(121, 'Cancel')"
		ok-variant="primary"
		:ok-disabled="loadingForSubmit"
		:cancel-disabled="loadingForSubmit"
		cancel-variant="custom-outline-gray"
		@ok.prevent="handleOk"
		@cancel="emitEventClose"
		@hidden="emitEventClose"
	>
		<div>
			<b-row>
				<b-col>
					<b-alert :show="showError" variant="danger" dismissible @dismissed="handleClickDismissed">
						{{ error }}
					</b-alert>
				</b-col>
			</b-row>
			<perfect-scrollbar ref="containerLocationEntryCarbon" class="container-location-entry-carbon" :class="{ 'container-mobile': $screen.width <= 576 }">
				<div id="containerLocationEntryCarbon" class="form" :style="`margin-bottom: ${$screen.width <= 576 ? '60px' : ''}`">
					<div v-for="(item, index) in carbonList" :key="index">
						<div v-if="$screen.width > 576" class="d-flex justify-content-around" :id="`item_${index}`">
							<div style="width: 92%">
								<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`" tyle="height: 100%">
									<legend
										v-if="$screen.width > 576"
										:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
										class="text-color-rhapsody-in-blue-2"
									>
										{{ FormMSG(653, 'Entry') + ' ' + (index + 1) }}
									</legend>
									<div class="pt-3 pb-3">
										<b-row v-if="item.entityType === 1 || item.entityType === 8">
											<b-col>
												<b-alert show variant="warning">
													<component :is="getLucideIcon('AlertTriangle')" :size="16" /> {{ getLabelAlertOtherEntity(item) }}
												</b-alert>
											</b-col>
										</b-row>
										<b-row class="align-items-center">
											<b-col sm="12" md="3" lg="3" xl="3">
												<b-form-group :label="FormMSG(654, 'Source')">
													<b-form-input :value="entityLabel" class="bg-color-grams-hair" disabled />
												</b-form-group>
											</b-col>
											<b-col sm="12" md="3" lg="3" xl="3">
												<b-form-group :label="FormMSG(810, 'Date')">
													<v-date-picker
														:value="item.strDate"
														:locale="lang"
														:masks="{ input: 'DD/MM/YYYY' }"
														@input="handleInputCarbonDateTime($event, index)"
													>
														<template v-slot="{ inputValue, togglePopover }">
															<b-input-group>
																<b-form-input
																	:value="inputValue"
																	readonly
																	:disabled="item.entityType === 1 || item.entityType === 8"
																	class="bg-white"
																	@click="togglePopover"
																/>
																<b-input-group-append>
																	<b-input-group-text
																		v-if="item.entityType === 1 || item.entityType === 8"
																		class="cursor-pointer"
																	>
																		<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
																	</b-input-group-text>
																	<b-input-group-text v-else class="cursor-pointer" @click="togglePopover">
																		<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
																	</b-input-group-text>
																</b-input-group-append>
															</b-input-group>
														</template>
													</v-date-picker>
												</b-form-group>
											</b-col>
											<b-col v-if="!isPremise">
												<b-form-checkbox
													:id="'ancillarySpaceConsumption' + index"
													v-model="item.ancillarySpaceConsumption"
													@change="handleInputAncillarySpaceConsumption($event, index)"
												>
													{{ FormMSG(655, 'Ancillary space consumption') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
										<co-two-clap
											:key="`coTwoClapLocation_${index}`"
											:parent-id="+item.entityId"
											:entity-type="1"
											:for-create="false"
											:external-carbon-data="item"
											:disable-all="item.entityType === 1 || item.entityType === 8"
											hide-btn-reviewed
											disable-carbon-type-selector
											hide-co-two-category-selector
											@co-two-clap:change="handleCoTwoClapChange($event, index)"
										/>
									</div>
								</fieldset>
							</div>
							<div v-if="$screen.width > 576" class="d-flex justify-content-center">
								<button
									type="button"
									class="btn-transparent text-color-burning-tomato"
									v-if="index > 0 && item.entityType !== 1 && item.entityType !== 8"
									@click="handleClickRemoveEntry(index)"
								>
									<component :is="getLucideIcon('MinusCircle')" :size="20" />
								</button>
								<button
									v-if="index === 0 || item.entityType === 1 || item.entityType === 8"
									type="button"
									v-b-tooltip.hover
									:title="getLabelTooltipDelete(item)"
									class="btn-transparent text-color-burning-tomato"
									disabled
								>
									<component :is="getLucideIcon('MinusCircle')" :size="20" />
								</button>
							</div>
						</div>
						<custom-accordion v-else :title="FormMSG(653, 'Entry') + ' ' + (index + 1)" :id="`item_${index}`" class="mb-3">
							<template #content>
								<div>
									<div>
										<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`" tyle="height: 100%">
											<legend
												v-if="$screen.width > 576"
												:class="`${$screen.width <= 576 ? '' : 'scheduler-border'}`"
												class="text-color-rhapsody-in-blue-2"
											>
												{{ FormMSG(653, 'Entry') + ' ' + (index + 1) }}
											</legend>
											<div class="pt-3 pb-3">
												<b-row v-if="item.entityType === 1 || item.entityType === 8">
													<b-col>
														<b-alert show variant="warning">
															<component :is="getLucideIcon('AlertTriangle')" :size="16" /> {{ getLabelAlertOtherEntity(item) }}
														</b-alert>
													</b-col>
												</b-row>
												<b-row>
													<b-col sm="12" md="3" lg="3" xl="3">
														<b-form-group :label="FormMSG(654, 'Source')">
															<b-form-input :value="entityLabel" class="bg-color-grams-hair" disabled />
														</b-form-group>
													</b-col>
													<b-col sm="12" md="3" lg="3" xl="3">
														<b-form-group :label="FormMSG(810, 'Date')">
															<v-date-picker
																:value="item.strDate"
																:locale="lang"
																:masks="{ input: 'DD/MM/YYYY' }"
																@input="handleInputCarbonDateTime($event, index)"
															>
																<template v-slot="{ inputValue, togglePopover }">
																	<b-input-group>
																		<b-form-input
																			:value="inputValue"
																			:disabled="item.entityType === 1 || item.entityType === 8"
																			readonly
																			class="bg-white"
																			@click="togglePopover"
																		/>
																		<b-input-group-append>
																			<b-input-group-text
																				v-if="item.entityType === 1 || item.entityType === 8"
																				class="cursor-pointer"
																			>
																				<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
																			</b-input-group-text>
																			<b-input-group-text v-else class="cursor-pointer" @click="togglePopover">
																				<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
																			</b-input-group-text>
																		</b-input-group-append>
																	</b-input-group>
																</template>
															</v-date-picker>
														</b-form-group>
													</b-col>
												</b-row>
												<co-two-clap
													:key="`coTwoClapLocation_${index}`"
													:parent-id="+item.entityId"
													:entity-type="1"
													:for-create="false"
													:disable-all="item.entityType === 1 || item.entityType === 8"
													:external-carbon-data="item"
													hide-btn-reviewed
													disable-carbon-type-selector
													hide-co-two-category-selector
													@co-two-clap:change="handleCoTwoClapChange($event, index)"
												/>
											</div>
										</fieldset>
									</div>
									<div
										v-if="$screen.width <= 576 && index > 0 && item.entityType !== 1 && item.entityType !== 8"
										class="d-flex justify-content-end"
									>
										<button class="btn-transparent text-color-burning-tomato fs-14 fw-700" @click="handleClickRemoveEntry(index)">
											<component :is="getLucideIcon('MinusCircle')" :size="20" /> {{ FormMSG(528, 'Delete') }}
										</button>
									</div>
								</div>
							</template>
						</custom-accordion>
					</div>
				</div>
			</perfect-scrollbar>

			<b-row>
				<b-col>
					<div
						class="d-flex mt-2"
						:class="{ 'justify-content-end': carbonToDelete.length === 0, 'justify-content-between': carbonToDelete.length > 0 }"
					>
						<div v-if="carbonToDelete.length > 0" class="d-flex">
							<div>
								<button
									class="btn-transparent text-color-blue-streak"
									v-b-tooltip.hover.righttop="
										FormMSG(427, 'The number displayed here is the number of entries you have saved and wish to delete.')
									"
								>
									<component :is="getLucideIcon('Info')" :size="16" />
								</button>
							</div>
							<div class="fs-14 fw-700 text-color-rhapsody-in-blue">
								{{ FormMSG(485, 'Entry to delete :') }}
							</div>
							<div class="fs-14 fw-700 text-color-negative">
								{{ carbonToDelete.length }}
							</div>
						</div>
						<button v-if="$screen.width > 576" type="button" class="btn-transparent text-color-rhapsody-in-blue" @click="handleClickAddEntry">
							<div class="d-flex">
								<div class="mr-2">
									<component :is="getLucideIcon('PlusCircle')" :size="20" color="#47C7BF" />
								</div>
								<div class="fs-14 fw-700">
									{{ FormMSG(45, 'Add entry') }}
								</div>
							</div>
						</button>
					</div>
				</b-col>
			</b-row>
		</div>

		<template #modal-footer="{ ok, cancel }">
			<div class="w-100">
				<div v-if="$screen.width <= 576">
					<b-button block variant="outline-primary" class="mb-2" @click="handleClickAddEntry">
						<component :is="getLucideIcon('PlusCircle')" :size="20" color="#47C7BF" />
						{{ FormMSG(45, 'Add entry') }}
					</b-button>

					<b-button block variant="primary" class="mb-2" @click="ok">
						<b-spinner v-if="loadingForSubmit" small />
						{{ FormMSG(135, 'Save') }}
					</b-button>
					<b-button block variant="custom-outline-gray" class="mb-2" :disabled="loadingForSubmit" @click="cancel">
						{{ FormMSG(43, 'Cancel') }}
					</b-button>
				</div>
				<div v-else class="w-100 d-flex justify-content-end align-items-center">
					<b-button size="md" variant="custom-outline-gray" class="w-138-px mr-3" :disabled="loadingForSubmit" @click="cancel" block>
						{{ FormMSG(43, 'Cancel') }}
					</b-button>
					<b-button :disabled="isInvalid" size="md" variant="primary" class="w-138-px" @click="ok">
						<b-spinner v-if="loadingForSubmit" small />
						{{ FormMSG(135, 'Save') }}
					</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import coTwoClap from './CoTwoClap.vue';
import _ from 'lodash';
import { INIT_DATA_CARBON } from '@/shared/constants';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { delCarbon, addUpdSliceCarbon } from '@/cruds/carbon.crud';
import CustomAccordion from '../CustomAccordion.vue';

export default {
	name: 'LocationEntryCarbonDialog',

	mixins: [languageMessages, globalMixin],

	props: {
		open: { type: Boolean, default: false, required: false },
		location: { type: Object, required: true },
		isPremise: { type: Boolean, default: false, required: false },
		carbons: { type: Array, default: () => [], required: false }
	},

	components: {
		'v-date-picker': DatePicker,
		coTwoClap,
		PerfectScrollbar,
		CustomAccordion
	},

	data() {
		return {
			carbonList: [],
			carbonToDelete: [],

			loadingForSubmit: false,
			showError: false,
			error: '',
			isInvalid: false
		};
	},

	watch: {
		// carbonList: {
		// 	handler(newVal) {

		// 	},
		// 	deep: true,
		// 	immediate: true
		// },
		carbons: {
			handler(newVal) {
				if (newVal.length > 0) {
					this.carbonList = [];
					for (let i = 0; i < newVal.length; i++) {
						this.carbonList.push({
							..._.omit(newVal[i], [
								'id',
								'projectId',
								'createdAt',
								'updatedAt',
								'supplier',
								'user',
								'department',
								'function',
								'carbonValues',
								'carbonTemplate'
							]),
							strDate: new Date(newVal[i].strDate),
							endDate: new Date(newVal[i].endDate),
							ancillarySpaceConsumption: newVal[i].ancillarySpaceConsumption,
							carbonId: +newVal[i].id,
							fromDatabase: true
						});
					}
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		},
		entityLabel() {
			return !this.isPremise ? this.FormMSG(658, 'Location') : this.FormMSG(659, 'Premise');
		},
		hideBackdropDialog() {
			return this.$screen.width <= 576;
		}
	},

	methods: {
		getLabelAlertOtherEntity(item) {
			let result = this.FormMSG(1987, 'This carbon is linked to ');

			if (item.entityType === 1) {
				result += this.FormMSG(1988, 'an expense.');
			} else if (item.entityType === 8) {
				result += this.FormMSG(1989, 'an external source.');
			}
			return result;
		},
		getLabelTooltipDelete(item) {
			if (item.entityType === 1 || item.entityType === 8) {
				return this.FormMSG(1993, "This line can't deleted. This carbon is linked with a other source");
			}

			return this.FormMSG(526, "This line can't deleted. This is the carbon default of location.");
		},
		handleClickDismissed() {
			this.showError = false;
			this.error = '';
		},
		async handleOk() {
			console.log(this.carbonList);
			try {
				this.loadingForSubmit = true;

				if (this.carbonToDelete.length > 0) {
					await delCarbon(this.carbonToDelete);
				}

				const result = await addUpdSliceCarbon(this.carbonList, this.isPremise ? 10 : 5, +this.location.id);

				this.emitEventSuccess(result);
				this.createToastForMobile(this.FormMSG(451, 'Success'), this.FormMSG(452, 'Data added and updated successfully!'));

				this.loadingForSubmit = false;
				this.emitEventClose();
			} catch (e) {
				this.loadingForSubmit = false;
				this.error = this.resolveGqlError(e);
				this.showError = true;
			}
		},
		async handleClickRemoveEntry(index) {
			if (this.carbonList[index].fromDatabase === true) {
				this.carbonToDelete.push(+this.carbonList[index].carbonId);
			}

			this.carbonList.splice(index, 1);
		},
		handleCoTwoClapChange({ carbonData }, index) {
			if (carbonData.elecMeterEnd - carbonData.elecMeterStart < 0) {
				this.isInvalid = true;
			} else if (carbonData.gasMeterEnd - carbonData.gasMeterStart < 0) {
				this.isInvalid = true;
			} else if (carbonData.waterMeterEnd - carbonData.waterMeterStart < 0) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
			this.carbonList[index] = {
				..._.omit(carbonData, ['strDate', 'endDate', 'entityType', 'entityId', 'carbonId']),
				strDate: this.carbonList[index].strDate,
				endDate: this.carbonList[index].endDate,
				entityType: this.carbonList[index].entityType,
				entityId: this.carbonList[index].entityId,
				carbonId: this.carbonList[index].carbonId,
				fromDatabase: this.carbonList[index].fromDatabase
			};
		},
		handleInputCarbonDateTime(payload, index) {
			this.carbonList[index].strDate = payload;
			this.carbonList[index].endDate = payload;
		},
		handleInputAncillarySpaceConsumption(payload, index) {
			console.log('change', payload);
			this.carbonList[index].ancillarySpaceConsumption = payload;
		},
		handleClickAddEntry() {
			let newCarbon = _.cloneDeep(INIT_DATA_CARBON);
			newCarbon.entityId = +this.location.id;
			newCarbon.entityType = !this.isPremise ? 5 : 10;
			newCarbon.strDate = new Date(this.location.getInDate);
			newCarbon.endDate = new Date(this.location.getInDate);
			newCarbon.entityId = +this.location.id;
			newCarbon.coTwoCategory = !this.isPremise ? 1 : 15;
			newCarbon.generatorFuelType = 0;
			newCarbon.reportType = 2;
			this.carbonList.push({
				...newCarbon,
				fromDatabase: false
			});

			this.$nextTick(() => {
				setTimeout(() => {
					const container = document.getElementById(`item_${this.carbonList.length - 1}`);

					this.$refs.containerLocationEntryCarbon.$el.scrollTo(0, this.$refs.containerLocationEntryCarbon.$el.scrollHeight);

					container.scrollIntoView({ behavior: 'smooth' });
				}, 250);
			});
		},
		emitEventSuccess(carbons) {
			this.$emit('location-entry-carbon:submit-success', { carbons });
		},
		emitEventClose() {
			this.carbonToDelete = [];
			this.carbonList = [];

			this.$emit('location-entry-carbon:close');
		}
	}
};
</script>

<style src="vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css" />
