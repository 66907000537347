<template>
	<div>
		<p class="mt-2 mb-2 fs-16 fw-700">{{ FormMSG(854, 'Main') }}</p>

		<b-row>
			<b-col
				sm="12"
				:md="detailsForGreenManager ? 3 : isLocation ? 5 : 3"
				:lg="detailsForGreenManager ? 3 : isLocation ? 5 : 3"
				:xl="detailsForGreenManager ? 3 : isLocation ? 5 : 3"
			>
				<b-form-group :label="isLocation ? FormMSG(863, 'Electricity (Location)') : FormMSG(862, 'Electricity')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionElectricityTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">{{ FormMSG(1259, 'kWh') }}</span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<b-form-checkbox v-if="isLocation" id="electricityFromPhotovoltaic" v-model="currentLocation.electricityFromPhotovoltaicSource" class="mb-2">
					{{ FormMSG(858, 'Electricity from photovoltaic source') }}
				</b-form-checkbox>
			</b-col>
			<b-col
				v-if="isLocation"
				sm="12"
				:md="detailsForGreenManager ? 3 : isLocation ? 5 : 3"
				:lg="detailsForGreenManager ? 3 : isLocation ? 5 : 3"
				:xl="detailsForGreenManager ? 3 : isLocation ? 5 : 3"
			>
				<b-form-group :label="FormMSG(861, 'Electricity (ancillary space)')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionElectricityAncillarySpaceTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">{{ FormMSG(1259, 'kWh') }}</span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<b-form-checkbox id="electricityFromPhotovoltaicSpace" v-model="currentLocation.electricityFromPhotovoltaicSourceAncillarySpace" class="mb-2">
					{{ FormMSG(858, 'Electricity from photovoltaic source') }}
				</b-form-checkbox>
			</b-col>
			<b-col
				sm="12"
				:md="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
				:lg="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
				:xl="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
			>
				<b-form-group :label="FormMSG(856, 'Gas')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionGasTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">m<sup>3</sup></span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col
				sm="12"
				:md="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
				:lg="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
				:xl="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
			>
				<b-form-group :label="FormMSG(1, 'Water')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionWaterTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">m<sup>3</sup></span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>

			<!-- <b-col v-if="detailsForGreenManager" sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(742, 'Fuel type')">
					<b-form-select v-model="generatorFuelType" :options="fuelTypeOptions" />
				</b-form-group>
			</b-col> -->
			<b-col
				:lg="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
				:md="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
				:xl="detailsForGreenManager ? 2 : isLocation ? 4 : 3"
			>
				<b-form-group :label="FormMSG(702, 'Fuel oil')">
					<b-input-group>
						<b-form-input :value="currentLocation.volumeTotalFuelOil" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">{{ generatorUnit }}</span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<!-- <p v-if="!detailsForGreenManager" class="mt-2 mb-2 fs-16 fw-700">{{ FormMSG(890, 'Generator') }}</p> -->

		<!-- <b-row v-if="!detailsForGreenManager">
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(742, 'Fuel type')">
					<b-form-select v-model="generatorFuelType" :options="fuelTypeOptions" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group :label="FormMSG(702, 'Fuel oil')">
					<b-input-group>
						<b-form-input :value="volumeUsed" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								<span class="font-weight-bold">{{ generatorUnit }}</span>
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row> -->

		<b-row class="mt-2">
			<b-col>
				<div class="d-flex justify-content-center">
					<b-button variant="outline-primary" @click="handleClickeAddEditConsumption">
						{{ FormMSG(705, 'Add/Edit consumption') }}
					</b-button>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { mapGetters } from 'vuex';

export default {
	name: 'ViewConsumptionEnergy',

	mixins: [languageMessages, globalMixin],

	props: {
		location: { type: Object, required: true },
		isLocation: { type: Boolean, default: true, required: false }
	},

	data() {
		return {
			currentLocation: {},
			generatorFuelType: 0
		};
	},

	watch: {
		location: {
			handler(newVal) {
				this.currentLocation = newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		...mapGetters({
			detailsForGreenManager: 'locations/detailsForGreenManager'
		}),
		fuelTypeOptions() {
			return this.FormMenu(1402);
		},
		volumeUsed() {
			// console.log(this.currentLocation)
			return this.currentLocation.volumeTotalFuelOil;
			// if (this.generatorFuelType === 0) {
			// 	return this.currentLocation.volumeTotalDieselUsed;
			// } else if (this.generatorFuelType === 1) {
			// 	return this.currentLocation.volumeTotalGasUsed;
			// } else if (this.generatorFuelType === 2) {
			// 	return this.currentLocation.volumeTotalGasolineUlUsed;
			// } else if (this.generatorFuelType === 3) {
			// 	return this.currentLocation.volumeTotalHydrogenUsed;
			// }
		},
		generatorUnit() {
			if (this.generatorFuelType === 0 || this.generatorFuelType === 2) {
				return this.FormMSG(1265, 'L');
			} else if (this.generatorFuelType === 1) {
				return this.FormMSG(1621, 'm3');
			} else if (this.generatorFuelType === 3) {
				return this.FormMSG(1622, 'kg');
			}
		}
	},

	methods: {
		handleClickeAddEditConsumption() {
			this.$emit('view-consumption-energy:add-edit');
		}
	}
};
</script>
